import React from "react"
import "react-datepicker/dist/react-datepicker.css"
// import { getTime } from "date-fns"
import { useDashboardContext } from "../contexts/DashboardContext"
import { GenericDropdown } from "./GenericDropdown"
import { statuses } from "../statuses"

const currentYear = new Date().getFullYear()
const years = [
  { id: 0, title: (currentYear - 1).toString(), label: (currentYear - 1).toString(), value: currentYear - 1 },
  { id: 1, title: currentYear.toString(), label: currentYear.toString(), value: currentYear },
  { id: 2, title: (currentYear + 1).toString(), label: (currentYear + 1).toString(), value: currentYear + 1 }
]

export function CustomProjectFiltersPanel({ ...props }) {
  const {
    selectedStatus,
    setSelectedStatus,
    // selectedFromDate,
    // setSelectedFromDate,
    // selectedToDate,
    // setSelectedToDate,
    clientFilterColumnEnabled,
    setClientFilterColumnEnabled,
    projectFilterColumnEnabled,
    setProjectFilterColumnEnabled,
    statusFilterColumnEnabled,
    setStatusFilterColumnEnabled,
    yearFilterColumnEnabled,
    setYearFilterColumnEnabled,
    yearFilterTerm,
    setYearFilterTerm,
    // fromDateFilterColumnEnabled,
    // setFromDateFilterColumnEnabled,
    // toDateFilterColumnEnabled,
    // setToDateFilterColumnEnabled,
    hideCancelledEnabled,
    setHideCancelledEnabled,
    clientFilterTerm,
    setClientFilterTerm,
    projectFilterTerm,
    setProjectFilterTerm,
  } = useDashboardContext()

  // const [isFromDateOpen, setIsFromDateOpen] = useState(false)
  // const [isToDateOpen, setIsToDateOpen] = useState(false)

  // const setDateRange = (dateFunction) => {
  //   const { dateStart, dateEnd } = dateFunction()
  //   setSelectedFromDate(dateStart)
  //   setSelectedToDate(dateEnd)
  // }

  // const isPresetDateRange = (datesFn) => {
  //   const { dateStart, dateEnd } = datesFn()
  //   return (
  //     getTime(selectedFromDate) === getTime(dateStart) &&
  //     getTime(selectedToDate) === getTime(dateEnd)
  //   )
  // }

  return (
    <div className='inline-flex flex-col items-center justify-center gap-2.5 p-5 relative bg-[#131b1f] border border-solid border-[#213843]'>
      <div className='inline-flex w-[350px] flex-col items-start gap-5 relative flex-[0_0_auto]'>
        {/*Client Filter*/}
        <div className='relative w-full h-[61px]'>
          <div className='flex items-center'>
            <div className='relative w-5 h-5'>
              <label className='cursor-pointer'>
                <input
                  type='checkbox'
                  className='peer sr-only'
                  checked={clientFilterColumnEnabled}
                  onChange={() => {
                    setClientFilterColumnEnabled(!clientFilterColumnEnabled)
                  }}
                />
                <div
                  className={`w-full h-full ${
                    clientFilterColumnEnabled
                      ? "bg-variable-collection-neongreen shadow-green-glow"
                      : "bg-gray-500"
                  } transition-all duration-300`}
                ></div>
              </label>
            </div>
            <div className='ml-2 font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] whitespace-nowrap [font-style:var(--2024-filter-option-font-style)]'>
              CLIENT
            </div>
          </div>
          <div className='mt-2 w-full h-[35px] bg-[#31404e80]'>
            <input
              className='p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-white text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none'
              value={clientFilterTerm}
              onChange={(e) => {
                setClientFilterTerm(e.target.value)
              }}
            />
          </div>
        </div>

        {/*Project Filter*/}
        <div className='relative w-full h-[61px]'>
          <div className='flex items-center'>
            <div className='relative w-5 h-5'>
              <label className='cursor-pointer'>
                <input
                  type='checkbox'
                  className='peer sr-only'
                  checked={projectFilterColumnEnabled}
                  onChange={() => {
                    setProjectFilterColumnEnabled(!projectFilterColumnEnabled)
                  }}
                />
                <div
                  className={`w-full h-full ${
                    projectFilterColumnEnabled
                      ? "bg-variable-collection-neongreen shadow-green-glow"
                      : "bg-gray-500"
                  } transition-all duration-300`}
                ></div>
              </label>
            </div>
            <div className='ml-2 font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] whitespace-nowrap [font-style:var(--2024-filter-option-font-style)]'>
              PROJECT
            </div>
          </div>
          <div className='mt-2 w-full h-[35px] bg-[#31404e80]'>
            <input
              className='p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-white text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none'
              value={projectFilterTerm}
              onChange={(e) => {
                setProjectFilterTerm(e.target.value)
              }}
            />
          </div>
        </div>

        {/*Status Filter*/}
        <div className='relative w-full h-[61px]'>
          <div className='flex items-center'>
            <div className='relative w-5 h-5'>
              <label className='cursor-pointer'>
                <input
                  type='checkbox'
                  className='peer sr-only'
                  checked={statusFilterColumnEnabled}
                  onChange={() => {
                    setStatusFilterColumnEnabled(!statusFilterColumnEnabled)
                  }}
                />
                <div
                  className={`w-full h-full ${
                    statusFilterColumnEnabled
                      ? "bg-variable-collection-neongreen shadow-green-glow"
                      : "bg-gray-500"
                  } transition-all duration-300`}
                ></div>
              </label>
            </div>
            <div className='ml-2 font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] whitespace-nowrap [font-style:var(--2024-filter-option-font-style)]'>
              STATUS
            </div>
          </div>
          <div className='mt-2 w-full h-[35px] bg-[#31404e80]'>
            <GenericDropdown
              items={statuses}
              placeholder={<div>Select Status</div>}
              selectedItemLabel={selectedStatus?.label}
              setSelectedItem={setSelectedStatus}
              onNewItemClick={(e) => {
                setSelectedStatus(statuses[parseInt(e.key)])
              }}
            />
          </div>
        </div>

        {/*Year Filter*/}
        <div className='relative w-full h-[61px]'>
          <div className='flex items-center'>
            <div className='relative w-5 h-5'>
              <label className='cursor-pointer'>
                <input
                  type='checkbox'
                  className='peer sr-only'
                  checked={yearFilterColumnEnabled}
                  onChange={() => {
                    setYearFilterColumnEnabled(!yearFilterColumnEnabled)
                  }}
                />
                <div
                  className={`w-full h-full ${
                    yearFilterColumnEnabled
                      ? "bg-variable-collection-neongreen shadow-green-glow"
                      : "bg-gray-500"
                  } transition-all duration-300`}
                ></div>
              </label>
            </div>
            <div className='ml-2 font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] whitespace-nowrap [font-style:var(--2024-filter-option-font-style)]'>
              YEAR
            </div>
          </div>
          <div className='mt-2 w-full h-[35px] bg-[#31404e80]'>
            <GenericDropdown
              items={years}
              placeholder={<div>Select Year</div>}
              selectedItemLabel={yearFilterTerm?.toString()}
              setSelectedItem={(item) => setYearFilterTerm(item.value)}
              onNewItemClick={(e) => {
                setYearFilterTerm(years[parseInt(e.key)].value)
              }}
            />
          </div>
        </div>

        {/*Hide Cancelled Filter*/}
        <div className='relative w-full '>
          <div className='flex items-center'>
            <div className='relative w-5 h-5'>
              <label className='cursor-pointer'>
                <input
                  type='checkbox'
                  className='peer sr-only'
                  checked={hideCancelledEnabled}
                  onChange={() => {
                    setHideCancelledEnabled(!hideCancelledEnabled)
                  }}
                />
                <div
                  className={`w-full h-full ${
                    hideCancelledEnabled
                      ? "bg-variable-collection-neongreen shadow-green-glow"
                      : "bg-gray-500"
                  } transition-all duration-300`}
                ></div>
              </label>
            </div>
            <div className='ml-2 font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] whitespace-nowrap [font-style:var(--2024-filter-option-font-style)]'>
              HIDE CANCELLED PROJECTS
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
