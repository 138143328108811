"use client"
import React, { useState, useCallback, useMemo, useEffect } from "react"
import { flexRender } from "@tanstack/react-table"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { fetcher, fetchAllPages } from "../fetcher"
import { useDashboardContext } from "../contexts/DashboardContext"
import { GenericDropdown } from "../components/GenericDropdown"
import { getStatusBackground, exportVisibleDataToCSV } from "../utils/helpers"
import { Popover, PopoverTrigger, PopoverContent } from "../components/popover"
import { CustomOptionsPanel } from "../components/CustomOptionsPanel"
import { CustomProjectFiltersPanel } from "../components/CustomProjectFiltersPanel"
import { useUserRole } from "../contexts/UserContext"
import { ROLES, hasRoleAtMost } from "../utils/roles"
import "react-responsive-modal/styles.css"
import withPageContexts from "../hocs/withPageContexts"
import NewClientModal from "../components/NewClientModal"
import NewContactModal from "../components/NewContactModal"
import { CustomCommonTable } from "../components/CustomCommonTable"
import { CustomNewProjectDetails } from "../components/CustomNewProjectDetails"
import { CustomNewEvent } from "../components/CustomNewEvent"
import CellDivider from "../components/CellDivider"
import GenericModal from "../components/GenericModal"
import useViewStackWithModal from "../utils/useViewStack"

function PageComponent(props) {
  const {
    defaultColumns,
    globalFilter,
    setGlobalFilter,
    selectedStatus,
    setSelectedStatus,
    tableInstance,
    setTableInstance,
    sorting,
    setSorting,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
    columnOrder,
    setColumnOrder,
    selectedRow,
    setSelectedRow,
    expandedRow,
    setExpandedRow,
    highlightUUID,
    setHighlightUUID,
    yearFilterTerm,
    setYearFilterTerm,
    yearFilterColumnEnabled,
    setYearFilterColumnEnabled,
  } = useDashboardContext()

  const { role } = useUserRole()

  const queryClient = useQueryClient()
  const abortController = new AbortController()
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const year = new Date().getFullYear()

  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: ["getProjects", yearFilterColumnEnabled ? yearFilterTerm : null],
    queryFn: () => {
      const queryParams = yearFilterColumnEnabled && yearFilterTerm ? { year: yearFilterTerm } : {};
      return fetchAllPages("/api/project", queryParams, true, 40);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  })

  const sortedData = useMemo(() => {
    if (!data?.results || !sorting.length) return data;

    const [{ id, desc }] = sorting;
    const sortedResults = [...data.results].sort((a, b) => {
      const sortMap = {
        project_id: 'id',
        project_name: 'name',
        client_name: 'client'
      };
      
      const fieldId = sortMap[id] || id;
      let aVal = a[fieldId];
      let bVal = b[fieldId];
      
      if (aVal === null) return 1;
      if (bVal === null) return -1;

      aVal = String(aVal).toLowerCase();
      bVal = String(bVal).toLowerCase();
      
      return desc ? 
        bVal.localeCompare(aVal) : 
        aVal.localeCompare(bVal);
    });

    return { ...data, results: sortedResults };
  }, [data, sorting]);

  const { pushView, popView, popAllViewsAndClear, openModal } =
    useViewStackWithModal(setSelectedTemplate)

  const handleTableInstanceCreated = useCallback(
    (table) => {
      setTableInstance(table)
    },
    [setTableInstance, defaultColumns]
  )

  useEffect(() => {
  }, [sortedData, sorting, yearFilterTerm, yearFilterColumnEnabled]);

  const filterForMultipleStatuses = (row, columnId, filterValue) => {
    return filterValue.includes(row.getValue(columnId))
  }

  const contextPanel = (row) => {
    return (
      <div className='bg-[#131B1F] h-[34px] w-max p-3 flex justify-center items-center gap-[15px] absolute context-panel-adjustment left-0'>
        <img
          className='relative h-[20px] cursor-pointer'
          alt='Google calendar'
          src='static/img/google-calendar.png'
          onClick={() => {
            window.open(
              "https://calendar.google.com",
              "_blank",
              "noopener,noreferrer"
            )
          }}
        />
        <img
          className={`relative h-[20px] ${
            !row.original?.qbo_project_url
              ? "!pointer-events-none opacity-50"
              : ""
          }`}
          alt='Quickbooks'
          src='static/img/quickbooks.png'
          onClick={() => {
            if (row.original?.qbo_project_url) {
              window.open(
                row.original?.qbo_project_url,
                "_blank",
                "noopener,noreferrer"
              )
            }
          }}
        />
        <img
          className='relative h-[20px] !pointer-events-none opacity-50'
          alt='Monday'
          src='static/img/monday.png'
        />
        <img
          className={`relative h-[20px] ${
            !row.original?.harvest_project_url
              ? "!pointer-events-none opacity-50"
              : ""
          }`}
          alt='Harvest'
          src='static/img/harvest.svg'
          onClick={() => {
            if (row.original?.harvest_project_url) {
              window.open(
                row.original?.harvest_project_url,
                "_blank",
                "noopener,noreferrer"
              )
            }
          }}
        />
        <img
          className={`relative h-[20px] ${
            !row.original?.dropbox_folders?.[0]
              ? "!pointer-events-none opacity-50"
              : ""
          }`}
          alt='Dropbox'
          src='static/img/dropbox.svg'
          onClick={() => {
            if (row.original?.dropbox_folders[0]) {
              window.open(
                row.original?.dropbox_folders[0],
                "_blank",
                "noopener,noreferrer"
              )
            }
          }}
        />
        <img
          className='relative h-[20px] !pointer-events-none opacity-50'
          alt='Current RMS'
          src='static/img/currentrms.png'
        />
        <img
          className='relative h-[20px] !pointer-events-none opacity-50'
          alt='Confluence'
          src='static/img/confluence.png'
        />
      </div>
    )
  }
  const handleExportClick = () => {
    if (!tableInstance) return

    const visibleColumns = tableInstance
      .getAllLeafColumns()
      .filter((column) => column.getIsVisible())

    const visibleData = tableInstance.getRowModel().rows.map((row) => {
      const rowData = {}
      visibleColumns.forEach((column) => {
        rowData[column.id] = row.getValue(column.id)
      })
      return rowData
    })

    exportVisibleDataToCSV(visibleData)
  }
  function Header(tableInstance, columnOrder, setColumnOrder) {
    return (
      <div className='flex items-center justify-between w-full gap-5 h-fit'>
        <div className='shrink-0 font-2024-page-title font-[number:var(--2024-page-title-font-weight)] text-white text-[length:var(--2024-page-title-font-size)] tracking-[var(--2024-page-title-letter-spacing)] leading-[var(--2024-page-title-line-height)] whitespace-nowrap [font-style:var(--2024-page-title-font-style)]'>
          Projects
        </div>
        <button
          onClick={(e) => {
            refetch()
          }}
        >
          <img
            className='h-full pt-2 pb-2'
            alt='Cog'
            src='static/refresh.svg'
          />
        </button>
        <Popover>
          <PopoverTrigger className='flex items-center h-full'>
            <img
              className='h-full pt-2 pb-2'
              alt='Filter'
              src='static/filter.svg'
            />
          </PopoverTrigger>
          <PopoverContent className='Popover' style={{ zIndex: 10 }}>
            <CustomProjectFiltersPanel />
          </PopoverContent>
        </Popover>

        <Popover>
          <PopoverTrigger className='flex items-center h-full'>
            <img className='h-full pt-2 pb-2' alt='Cog' src='static/cog.svg' />
          </PopoverTrigger>
          <PopoverContent className='Popover' style={{ zIndex: 10 }}>
            {tableInstance?.getAllLeafColumns && defaultColumns?.length > 0 ? (
              <CustomOptionsPanel
                columns={tableInstance.getAllLeafColumns()}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columnVisibility={columnVisibility}
              />
            ) : (
              <div className="p-4 text-white">Loading columns...</div>
            )}
          </PopoverContent>
        </Popover>
        <div className='flex-grow relative h-8'>
          <div className='absolute w-full h-8 top-0 left-0 bg-[#31404d80] opacity-70' />
          <img
            className='absolute w-4 h-4 top-2 left-2.5'
            alt='Search icon'
            src='static/search-icon.svg'
          />
          <input
            type='text'
            className='absolute inset-0 w-full h-full bg-transparent border-none pl-10'
            placeholder=''
            value={globalFilter}
            onChange={(e) => {
              if (globalFilter !== e.target.value) {
                setGlobalFilter(e.target.value)
              }
            }}
          />
        </div>
        <div className='relative w-px h-12 bg-[#d9d9d980]' />
        <button
          className='relative w-[24px] h-[24px] flex items-center justify-center'
          onClick={handleExportClick}
        >
          <img
            alt='Archive'
            src='static/archive.svg'
            className='w-full h-full'
          />
        </button>
        <div
          className={`relative w-[182px] h-8 mr-[-2.00px] ${
            hasRoleAtMost(role, ROLES.PROJECTMANAGER) && "!hidden"
          }`}
        >
          <div
            className={`w-[180px] bg-variable-collection-neongreen rounded-[35px] shadow-green-glow relative h-8`}
          >
            <button
              onClick={() => {
                setSelectedTemplate(null)
                pushView("newProjectDetails")
              }}
              className={`absolute top-1.5 left-[42px] font-2024-button-main font-[number:var(--2024-button-main-font-weight)] text-variable-collection-black-1 text-[length:var(--2024-button-main-font-size)] text-right tracking-[var(--2024-button-main-letter-spacing)] leading-[var(--2024-button-main-line-height)] whitespace-nowrap [font-style:var(--2024-button-main-font-style)] `}
            >
              +PROJECT
            </button>
          </div>
        </div>
      </div>
    )
  }

  function NewProjectInitModal({
    openModal,
    popAllViewsAndClear,
    setSelectedTemplate,
    pushView,
  }) {
    return (
      <GenericModal
        modalName={"newProject"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <div className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
            NEW PROJECT
          </div>
          <p className='relative self-stretch font-2024-panel-body font-[number:var(--2024-panel-body-font-weight)] text-white text-[length:var(--2024-panel-body-font-size)] tracking-[var(--2024-panel-body-letter-spacing)] leading-[var(--2024-panel-body-line-height)] [font-style:var(--2024-panel-body-font-style)]'>
            Create from scratch or use a template from an existing project?
          </p>
          <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
            <button
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9] cursor-pointer'
              onClick={() => {
                popAllViewsAndClear()
              }}
            >
              <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
                CANCEL
              </div>
            </button>
            <button
              className='flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
              onClick={() => {
                setSelectedTemplate(null)
                pushView("newProjectDetails")
              }}
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                SCRATCH
              </div>
            </button>
            <button
              className='flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
              onClick={() => {
                pushView("newProjectTemplate")
              }}
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                TEMPLATE
              </div>
            </button>
          </div>
        </div>
      </GenericModal>
    )
  }
  function NewProjectTemplateModal({
    openModal,
    popAllViewsAndClear,
    setSelectedTemplate,
    pushView,
  }) {
    return (
      <GenericModal
        modalName={"newProjectTemplate"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <div className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
            NEW PROJECT TEMPLATE
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <GenericDropdown
              items={sortedData?.results}
              placeholder={<div>Select Project</div>}
              selectedItemLabel={selectedTemplate?.name_string}
              setSelectedItem={(selectedProject) => {
                setSelectedTemplate(selectedProject)
              }}
              listWidth={332}
            />
          </div>
          <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
            <button
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9]'
              onClick={() => {
                setSelectedTemplate(null)
                popAllViewsAndClear()
              }}
            >
              <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
                CANCEL
              </div>
            </button>
            <button
              className='flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
              onClick={() => {
                pushView("newProjectDetails")
              }}
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                NEXT
              </div>
            </button>
          </div>
        </div>
      </GenericModal>
    )
  }

  function NewProjectDetailsModal({
    openModal,
    popAllViewsAndClear,
    pushView,
    popView,
  }) {
    return (
      <GenericModal
        modalName={"newProjectDetails"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <CustomNewProjectDetails
          projectsData={sortedData?.results}
          template={selectedTemplate}
          finishedPosting={(uuid) => {
            setHighlightUUID(uuid)
            setSelectedRow(null)
            setExpandedRow(null)
            popAllViewsAndClear()
            refetch()
          }}
          goBack={() => {
            popView()
          }}
          newClientTapped={(e) => {
            pushView("newClient")
          }}
          newEventTapped={() => {
            pushView("newEvent")
          }}
          openCalendar={() => {
            console.log("opening calendar")
            pushView("calendar")
          }}
        />
      </GenericModal>
    )
  }
  function NewEventModal({ openModal, popAllViewsAndClear, popView }) {
    return (
      <GenericModal
        modalName={"newEvent"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <CustomNewEvent
          onBack={() => {
            popView()
          }}
          onFinished={() => {
            popView()
          }}
        />
      </GenericModal>
    )
  }
  return (
    <div className='flex h-screen flex-col overflow-x-auto p-7.5 overflow-y-hidden'>
      {Header(tableInstance, columnOrder, setColumnOrder)}
      <div className='flex-1 flex flex-col min-h-0'>
        <div className='flex-1 min-h-0'>
          <div className='flex-grow overflow-auto h-full'>
            <CustomCommonTable
              isLoading={isLoading}
              isFetching={isFetching}
              data={sortedData?.results || []}
              columns={defaultColumns}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              highlightUUID={highlightUUID}
              setHighlightUUID={setHighlightUUID}
              expandedRow={expandedRow}
              filterFns={{
                filterForMultipleStatuses,
              }}
              onTableInstanceCreated={handleTableInstanceCreated}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              sorting={sorting}
              setSorting={setSorting}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              columnSizing={columnSizing}
              setColumnSizing={setColumnSizing}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              getHeaderCellStyle={{
                Invoices: (header) => {
                  return {
                    position: "relative",
                    width: header?.getSize?.(),
                    minWidth: 60,
                  }
                },
                Estimates: (header) => {
                  return {
                    position: "relative",
                    width: header?.getSize?.(),
                    minWidth: 60,
                  }
                },
              }}
              getContentCell={{
                Estimates: (cell, row) => (
                  <div
                    key={cell.id}
                    className={`relative table-body ${
                      row.original?.has_quickbooks_estimate
                        ? "bg-qbo-status-green !h-full"
                        : "bg-qbo-status-red !h-full"
                    } td capitalize ${
                      cell.column.columnDef.id === "status"
                        ? getStatusBackground(cell, row)
                        : ""
                    }`}
                    style={{
                      width: cell.column.getSize(),
                      minWidth: 60,
                      overflow: "visible",
                    }}
                  >
                    <div className={`!overflow-visible`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                    <CellDivider />
                  </div>
                ),
                Invoices: (cell, row) => (
                  <div
                    key={cell.id}
                    className={`relative table-body ${
                      row.original?.has_quickbooks_invoice
                        ? "bg-qbo-status-green !h-full"
                        : "bg-qbo-status-red !h-full"
                    } td capitalize ${
                      cell.column.columnDef.id === "status"
                        ? getStatusBackground(cell, row)
                        : ""
                    }`}
                    style={{
                      width: cell.column.getSize(),
                      minWidth: 60,
                      overflow: "visible",
                    }}
                  >
                    <div className={`!overflow-visible`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                    <CellDivider />
                  </div>
                ),
                project_id: (cell, row) => (
                  <div
                    key={cell.id}
                    className={`relative table-body td capitalize ${
                      cell.column.columnDef.id === "status"
                        ? getStatusBackground(cell, row)
                        : ""
                    }`}
                    style={{
                      width: cell.column.getSize(),
                      minWidth: 120,
                      overflow: "visible",
                    }}
                  >
                    {expandedRow === row.id ? (
                      <div className='!overflow-visible h-full'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        {contextPanel(row)}
                      </div>
                    ) : (
                      <div className='!overflow-visible w-full h-full text-center flex justify-center items-center'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    )}
                    <CellDivider />
                  </div>
                ),
              }}
              enableRowSelection={true}
              contextPanel={contextPanel}
            />
          </div>
          
          <div className="flex gap-2 h-fit">
            {[year - 1, year, year + 1].map((tabYear) => (
              <button
                key={tabYear}
                className={`px-2 py-0 bg-variable-collection-neongreen shadow-green-glow text-black ${yearFilterColumnEnabled && yearFilterTerm === tabYear ? 'opacity-100' : 'opacity-50'}`}
                onClick={() => {
                  setYearFilterTerm(tabYear)
                  setYearFilterColumnEnabled(true)
                }}
              >
                {tabYear}
              </button>
            ))}
          </div>
        </div>
      </div>
      {NewProjectInitModal({
        openModal,
        popAllViewsAndClear,
        setSelectedTemplate,
        pushView,
      })}
      {NewProjectTemplateModal({
        openModal,
        popAllViewsAndClear,
        setSelectedTemplate,
        pushView,
      })}
      {NewProjectDetailsModal({
        openModal,
        popAllViewsAndClear,
        pushView,
        popView,
      })}
      {NewClientModal({
        openModal,
        popAllViewsAndClear,
        pushView,
        popView,
      })}
      {NewContactModal({
        openModal,
        popAllViewsAndClear,
        popView,
      })}
      {NewEventModal({
        openModal,
        popAllViewsAndClear,
        popView,
      })}
    </div>
  )
}
export default withPageContexts(PageComponent, "/dashboard")
